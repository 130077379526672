import React, { useState, useEffect } from 'react';
import { Menu, Button, Modal, Form, Message } from 'semantic-ui-react';
import { CATEGORIES, DIFFICULTY, QUESTIONS_TYPE } from '../../constants';

const Header = () => {
  const [promptEvent, setPromptEvent] = useState(null);
  const [appAccepted, setAppAccepted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    category: '',
    type: '',
    difficulty: '',
    question: '',
    correct_answer: '',
    incorrect_answer1: '',
    incorrect_answer2: '',
    incorrect_answer3: '',
    explanation: ''
  });

  let isAppInstalled = false;

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches || appAccepted) {
      isAppInstalled = true;
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setPromptEvent(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [appAccepted]);

  const installApp = () => {
    promptEvent.prompt();
    promptEvent.userChoice.then(result => {
      if (result.outcome === 'accepted') {
        setAppAccepted(true);
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
    });
  };

  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const questionData = {
      category: formData.category,
      type: formData.type,
      difficulty: formData.difficulty,
      question: formData.question,
      correct_answer: formData.correct_answer,
      incorrect_answer1: formData.incorrect_answer1,
      incorrect_answer2: formData.incorrect_answer2,
      incorrect_answer3: formData.incorrect_answer3,
      explanation: formData.explanation
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionData)
      });

      if (!response.ok) {
        throw new Error('Erro ao adicionar pergunta');
      }

      setFormData({
        category: '',
        type: '',
        difficulty: '',
        question: '',
        correct_answer: '',
        incorrect_answer1: '',
        incorrect_answer2: '',
        incorrect_answer3: '',
        explanation: ''
      });

      setModalOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const renderQuestionInputs = () => {
    if (formData.type === 'multiple') {
      return (
        <>
          <Form.Input
            label="Resposta Correta"
            name="correct_answer"
            value={formData.correct_answer}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Resposta Incorreta 1"
            name="incorrect_answer1"
            value={formData.incorrect_answer1}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Resposta Incorreta 2"
            name="incorrect_answer2"
            value={formData.incorrect_answer2}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Resposta Incorreta 3"
            name="incorrect_answer3"
            value={formData.incorrect_answer3}
            onChange={handleInputChange}
          />
        </>
      );
    } else if (formData.type === 'boolean') {
      return (
        <Form.Group>
          <Form.Radio
            label="Verdadeiro"
            name="correct_answer"
            value="True"
            checked={formData.correct_answer === 'True'}
            onChange={() => {
              setFormData({
                ...formData,
                correct_answer: 'True',
                incorrect_answer1: 'False',
              });
            }}
          />
          <Form.Radio
            label="Falso"
            name="correct_answer"
            value="False"
            checked={formData.correct_answer === 'False'}
            onChange={() => {
              setFormData({
                ...formData,
                correct_answer: 'False',
                incorrect_answer1: 'True',
              });
            }}
          />
        </Form.Group>
      );
    }
  };

  return (
    <>
      <Menu stackable inverted>
        <Menu.Item header>
          <div style={{ height: 50 }}>
            <h1 style={{ marginBottom: 1 }}>Simíles CyD UNR</h1>
            <p style={{ fontSize: 10 }}>Desenvolvido por Maycon Brian</p>
          </div>
        </Menu.Item>
        <Menu.Item position="right">
          <Button
            color="green"
            icon="plus"
            labelPosition="left"
            content="Adicionar Pergunta"
            onClick={() => setModalOpen(true)}
          />
        </Menu.Item>
      </Menu>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Adicionar Pergunta</Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <Form.Dropdown
              label="Categoria"
              name="category"
              options={CATEGORIES}
              selection
              value={formData.category}
              onChange={handleInputChange}
            />
            <Form.Dropdown
              label="Tipo"
              name="type"
              options={QUESTIONS_TYPE}
              selection
              value={formData.type}
              onChange={handleInputChange}
            />
            <Form.Dropdown
              label="Dificuldade"
              name="difficulty"
              options={DIFFICULTY}
              selection
              value={formData.difficulty}
              onChange={handleInputChange}
            />
            <Form.TextArea
              label="Pergunta"
              name="question"
              value={formData.question}
              onChange={handleInputChange}
            />
            {renderQuestionInputs()}
            <Form.TextArea
              label="Explicação (Opcional)"
              name="explanation"
              value={formData.explanation}
              onChange={handleInputChange}
            />
            {error && (
              <Message error onDismiss={() => setError('')}>
                <Message.Header>Erro!</Message.Header>
                <p>{error}</p>
              </Message>
            )}
            <Button type="submit" color="green">Adicionar</Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Header;
