const DIFFICULTY = [
  {
    key: '0',
    text: 'Todos',
    value: '0',
  },
  {
    key: 'easy',
    text: 'Fácil',
    value: 'easy',
  },
  {
    key: 'medium',
    text: 'Medio',
    value: 'medium',
  },
  {
    key: 'hard',
    text: 'Difícil',
    value: 'hard',
  },
];

export default DIFFICULTY;
