import React from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import { Card, Icon, Segment } from 'semantic-ui-react';

const QNA = ({ questionsAndAnswers }) => {
  return (
    <Card.Group centered itemsPerRow={1}>
      {questionsAndAnswers.map((item, i) => (
        <Card key={i} color={item.point > 0 ? 'green' : 'red'} fluid>
          
          <Card.Content>
            <Card.Header>
              N° {i + 1}
            </Card.Header>
            <Card.Description>
              <strong>Pregunta:</strong> {item.question}
            </Card.Description>
            <Card.Description>
              <strong>Su Respuesta:</strong> {item.user_answer}
            </Card.Description>
            <Card.Description>
              <strong>Respuesta Correcta:</strong> {item.correct_answer}
            </Card.Description>
            <Segment color={item.point > 0 ? 'green' : 'red'} raised>
              <Icon name={item.point > 0 ? 'check circle' : 'times circle'} />
              <strong>Punto:</strong> {item.point}
            </Segment>
            {item.explanation && (
              <Card.Description>
                <strong>Explicación:</strong> {item.explanation}
              </Card.Description>
            )}
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
};

QNA.propTypes = {
  questionsAndAnswers: PropTypes.array.isRequired,
};

export default QNA;