import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Segment,
  Item,
  Dropdown,
  Divider,
  Button,
  Message,
  Form,
} from 'semantic-ui-react';

import {
  CATEGORIES,
  NUM_OF_QUESTIONS,
  DIFFICULTY,
  QUESTIONS_TYPE,
} from '../../constants';
import { shuffle } from '../../utils';

import Offline from '../Offline';
import CustomNumberInput from '../CustomNumberInput'; 

const Main = ({ startQuiz }) => {
  const [category, setCategory] = useState('0');
  const [numOfQuestions, setNumOfQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState('0');
  const [questionsType, setQuestionsType] = useState('0');
  const [countdownTime, setCountdownTime] = useState({
    hours: 0,
    minutes: 20,
    seconds: 0,
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [offline, setOffline] = useState(false);

  const handleTimeChange = (e, { name, value }) => {
    setCountdownTime({ ...countdownTime, [name]: parseInt(value, 10) || 0 });
  };

  let allFieldsSelected = false;
  if (
    category &&
    numOfQuestions &&
    difficulty &&
    questionsType &&
    (countdownTime.hours || countdownTime.minutes || countdownTime.seconds)
  ) {
    allFieldsSelected = true;
  }

  const fetchData = () => {
    setProcessing(true);

    if (error) setError(null);

    const API = `${process.env.REACT_APP_BACKEND_URL}/api/questions?amount=${numOfQuestions}&category=${category}&difficulty=${difficulty}&type=${questionsType}`;
    fetch(API)
      .then(response => response.json())
      .then(data => {
        setTimeout(() => {
          if (!data || data.length === 0) {
            const message = (
              <p>
                La API no tiene suficientes preguntas para tu consulta. (Por
                ejemplo, pedir 50 preguntas en una categoría que solo tiene 20.)
                <br />
                <br />
                Por favor cambia el <strong>Número de Preguntas</strong>,{' '}
                <strong>Nivel de Dificultad</strong>, o{' '}
                <strong>Tipo de Preguntas</strong>.
              </p>
            );

            setProcessing(false);
            setError({ message });

            return;
          }

          data.forEach(element => {
            element.options = shuffle([
              element.correct_answer,
              ...element.incorrect_answers,
            ]);
          });
          setProcessing(false);
          startQuiz(
            data,
            countdownTime.hours * 3600 + countdownTime.minutes * 60 + countdownTime.seconds
          );
        }, 1000);
      })
      .catch(error =>
        setTimeout(() => {
          if (!navigator.onLine) {
            setOffline(true);
          } else {
            setProcessing(false);
            setError(error);
          }
        }, 1000)
      );
  };

  if (offline) return <Offline />;

  return (
    <Container>
      <Segment>
        <Item.Group divided>
          <Item>
            <Item.Content>
              <Item.Header>
                <h1>Simíles UNR</h1>
              </Item.Header>
              {error && (
                <Message error onDismiss={() => setError(null)}>
                  <Message.Header>¡Error!</Message.Header>
                  {error.message}
                </Message>
              )}
              <Divider />
              <Item.Meta>
                <p>¿Selecciona el curso y su unidad problema?</p>
                <Dropdown
                  fluid
                  selection
                  name="category"
                  placeholder="Selecciona tu UP"
                  header="Selecciona tu UP"
                  options={CATEGORIES}
                  value={category}
                  onChange={(e, { value }) => setCategory(value)}
                  disabled={processing}
                />
                <br />
                <p>¿Selecciona la cantidad de preguntas?</p>
                <Dropdown
                  fluid
                  selection
                  name="numOfQ"
                  placeholder="Selecciona el Número de Preguntas"
                  header="Selecciona el Número de Preguntas"
                  options={NUM_OF_QUESTIONS}
                  value={numOfQuestions}
                  onChange={(e, { value }) => setNumOfQuestions(value)}
                  disabled={processing}
                />
                <br />
                <p>¿Cuál es la dificultad?</p>
                <Dropdown
                  fluid
                  selection
                  name="difficulty"
                  placeholder="Selecciona la dificultad"
                  header="Selecciona la dificultad"
                  options={DIFFICULTY}
                  value={difficulty}
                  onChange={(e, { value }) => setDifficulty(value)}
                  disabled={processing}
                />
                <br />
                <p>¿Elige el tipo de pregunta?</p>
                <Dropdown
                  fluid
                  selection
                  name="type"
                  placeholder="Selecciona el tipo de pregunta"
                  header="Selecciona el tipo de pregunta"
                  options={QUESTIONS_TYPE}
                  value={questionsType}
                  onChange={(e, { value }) => setQuestionsType(value)}
                  disabled={processing}
                />
                <br />
                <p>Selecciona el tiempo del simulacro.</p>
                <Form.Group inline style={{ display: 'flex' }}>
                  <CustomNumberInput
                    label="Hora"
                    name="hours"
                    value={countdownTime.hours}
                    onChange={handleTimeChange}
                    disabled={processing}
                  />
                  <CustomNumberInput
                    label="Minuto"
                    name="minutes"
                    value={countdownTime.minutes}
                    onChange={handleTimeChange}
                    disabled={processing}
                  />
                  <CustomNumberInput
                    label="Segundo"
                    name="seconds"
                    value={countdownTime.seconds}
                    onChange={handleTimeChange}
                    disabled={processing}
                  />
                </Form.Group>
              </Item.Meta>
              <Divider />
              <Item.Extra>
                <Button
                  primary
                  size="big"
                  icon="play"
                  labelPosition="left"
                  content={processing ? 'Procesando...' : 'Comenzar'}
                  onClick={fetchData}
                  disabled={!allFieldsSelected || processing}
                />
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <br />
    </Container>
  );
};

Main.propTypes = {
  startQuiz: PropTypes.func.isRequired,
};

export default Main;

