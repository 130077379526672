const CATEGORIES = [
  {
    key: '0',
    text: 'Todas las Categorías',
    value: '0',
  },
  {
    key: 12,
    text: 'CyD: UP4',
    value: 12,
  },
  {
    key: 13,
    text: 'CyD: UP5',
    value: 13,
  },
  {
    key: 14,
    text: 'CyD: UP6',
    value: 14,
  },
  {
    key: 15,
    text: 'CyD: UP7',
    value: 15,
  },
  {
    key: 16,
    text: 'CyD: UP8',
    value: 16,
  },
  {
    key: 17,
    text: 'CyD: UP9',
    value: 17,
  },
  {
    key: 18,
    text: 'Nutri: UP1',
    value: 18,
  },
  {
    key: 19,
    text: 'Nutri: UP2',
    value: 19,
  },
];

export default CATEGORIES;
