import React from 'react';
import { Icon } from 'semantic-ui-react';

const CustomNumberInput = ({ label, name, value, onChange, disabled }) => {

    const increment = () => {
        onChange(null, { name, value: value + 1 });
    };

    const decrement = () => {
        if (value > 0) {
            onChange(null, { name, value: value - 1 });
        }
    };

  return (
    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', border: '1px solid #ccc', borderRadius: '10px', padding: '1em', margin: '0px 0.5em 0.5em 0em' }}>
      <label style={{ backgroundColor: '#ccc', borderRadius: 5, padding: 4, color: 'black', marginBottom: 10, marginRight: '-1px'}}>{label}</label>
      <div style={{ position: 'relative', display: 'inline-block', marginTop: 4, width: '70px'}}>
        <Icon
          name="chevron up"
          style={{ cursor: 'pointer', position: 'absolute', top: '-2px', left: 'calc(50% - 8px)' }}
          onClick={increment}
        />
        <span
          style={{ 
            textAlign: 'center', 
            color: 'black',
            width: '50px',
            height: '30px',
            borderRadius: '15px',
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: 'calc(50% - 25px)',
            display: 'flex',
            fontSize: '15px',
            padding: '4px',
            border: '1px solid #ccc',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {value}
        </span>
        <Icon
          name="chevron down"
          style={{ cursor: 'pointer', position: 'absolute', bottom: '-2px', left: 'calc(50% - 8px)' }}
          onClick={decrement}
        />
      </div>
    </div>
  );
};

export default CustomNumberInput;
