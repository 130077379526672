const QUESTIONS_TYPE = [
  {
    key: '0',
    text: 'Todos',
    value: '0',
  },
  {
    key: 'multiple',
    text: 'Elección Múltiple',
    value: 'multiple',
  },
  {
    key: 'boolean',
    text: 'Verdadero / Falso',
    value: 'boolean',
  },
];

export default QUESTIONS_TYPE;
